
const TokenKey = "ACCESS-TOKEN";
import { storage } from '@/utils/Storage';


export function getToken(): string | any {
  return storage.get(TokenKey) ? `Bearer ${storage.get(TokenKey)}` : "";
}

export function setToken(token: string) {
  return storage.set(TokenKey, token);
}

export function removeToken() {
  return storage.remove(TokenKey);
}
