import { createApp } from 'vue';
import App from './App.vue';
import './style.css';
import router from './router';
import 'amfe-flexible';
import { setupStore } from '@/store';
import 'virtual:uno.css';
// 适配pc端
import '@vant/touch-emulator';
// Toast
import 'vant/es/toast/style';
// Dialog
import 'vant/es/dialog/style';
// Notify
import 'vant/es/notify/style';
// ImagePreview
import 'vant/es/image-preview/style';
import VConsole from "vconsole"


// 开启vconsole调试
if (import.meta.env.VITE_DEBUG_VCONSOLE == 'true') {
  new VConsole();
}

const app = createApp(App);

// 挂载状态管理
setupStore(app);

app.use(router);
app.mount('#app');
