import request from '@/utils/request';

/**
 * @description: 获取用户信息
 */
export function getUserInfo() {
  return request({
    url: '/system/getInfo',
    method: 'get',
  });
}

/**
 * @description: 用户登录
 */
export function login(data) {
  return request({
    url: '/system/login',
    method: 'post',
    data,
  });
}

/**
 * @description: 用户修改密码
 */
export function changePassword(data, uid) {
  return request({
    url: `/user/u${uid}/changepw`,
    method: 'post',
    data,
  });
}

/**
 * @description: 用户登出
 */
export function logout(data) {
  return request({
    url: '/login/logout',
    method: 'post',
    data,
  });
}

/**
 * 列表
 * @param params
 */
export function getList(params?) {
  return request({
    url: '/systemUser/list',
    method: 'get',
    params,
  });
}

/**
 * 详情
 * @param params
 */
export function detail(params?) {
  return request({
    url: '/systemUser/detail',
    method: 'get',
    params,
  });
}

/**
 * 编辑
 * @param data
 */
export function update(data?) {
  return request({
    url: '/systemUser/update',
    method: 'post',
    data,
  });
}
/**
 * 新增
 * @param data
 */
export function create(data?) {
  return request({
    url: '/systemUser/create',
    method: 'post',
    data,
  });
}

/**
 * 删除
 * @param data
 */
export function del(data?) {
  return request({
    url: '/systemUser/delete',
    method: 'post',
    data,
  });
}

/**
 * 设置用户角色
 * @param data
 */
export function setUserRole(data?) {
  return request({
    url: '/systemUser/setUserRole',
    method: 'post',
    data,
  });
}

/**
 * 获取当前用户信息
 * @param params
 */
export function getCurrentUser(params?) {
  return request({
    url: '/systemUser/getCurrentUser',
    method: 'get',
    params,
  });
}

/**
 * 设置当前用户信息
 * @param params
 */
export function setCurrentUser(data?) {
  return request({
    url: '/systemUser/setCurrentUser',
    method: 'post',
    data,
  });
}
