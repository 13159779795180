/**
 * 生成微信授权页面链接，用于引导用户进行微信授权
 *
 * @param appId 微信应用的唯一标识符，用于识别不同的微信应用
 * @param redirect_uri 授权后重定向的回调地址，微信服务器在完成授权后会重定向到该地址，并附带code参数
 * @param scope 授权作用范围，可选值为'snsapi_base'或'snsapi_privateinfo'，默认为'snsapi_base'
 *               'snsapi_base'：只获取用户openid，用户在第三方应用上的授权页面不会显示授权详情
 *               'snsapi_privateinfo'：获取用户基本信息，用户在第三方应用上的授权页面会显示授权详情，并需要用户确认
 * @returns 返回构造好的微信授权页面链接
 */
export function getWeixinAuthUrl(
  appId: string,
  agentId: string,
  redirect_uri: string,
  scope: 'snsapi_base' | 'snsapi_privateinfo' = 'snsapi_base'
) {
  redirect_uri = encodeURIComponent(redirect_uri);
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=STATE&agentid=${agentId}#wechat_redirect`;
}

/**
 * 是否是企业微信内置浏览器
 * @returns
 */
export function isWeChatWorkBrowser() {
  var userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.indexOf('micromessenger') !== -1 ||
    userAgent.indexOf('wxwork') !== -1
  );
}

/**
 * 获取url参数
 * @param url
 * @returns
 */
export function getUrlParams(url: string = window.location.href) {
  let urlStr = url.split('?')[1];
  const urlSearchParams = new URLSearchParams(urlStr);
  const result = Object.fromEntries(urlSearchParams.entries());
  return result;
}
