<template>
  <router-view />
</template>

<script lang="ts" setup>
import {
  getWeixinAuthUrl,
  isWeChatWorkBrowser,
  getUrlParams,
} from '@/utils/weixin';
import { useUserStore } from '@/store/modules/user';
import { configJsSdk } from './utils/wxSdk';

let userStore = useUserStore();

// 存在code，说明是微信授权回调
let params = getUrlParams();
let code = params.code;
let share = params.share;
if (code) {
  userStore.setCode(code);
  // 传递给后端获取
  // 从地址栏删除 code 参数
  const url = new URL(window.location.href);
  url.searchParams.delete('code');
  history.replaceState({}, document.title, url.toString());
}
// 是微信环境并且没登录，构造授权链接[share分享链接不验证登录]
else if (!share && isWeChatWorkBrowser() && !userStore.getToken) {
  let url = getWeixinAuthUrl(
    'wwffe6ce656fb02bd9',
    '1000002',
    `${location.origin}/login`,
    'snsapi_privateinfo'
  );
  location.href = url;
}

// 测试授权
// configJsSdk().then(()=>{

// })
</script>
<style></style>
