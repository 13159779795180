import axios from 'axios';
import { getToken } from '@/utils/auth';
import {useUserStore} from "@/store/modules/user"
import { showToast } from 'vant';

declare const window: Window & { $message: any; $loading: any };
// create an axios instance
const service = axios.create({
  baseURL: `${import.meta.env.VITE_APP_BASE_API}${import.meta.env.VITE_GLOB_API_URL_PREFIX}` as string, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300 * 1000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config: any) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = token;
    }
    // get 请求
    if (config.method.toLocaleUpperCase() === 'GET' && config.data) {
      config.params = config.data;
      delete config.data;
    }

    return config;
  },
  (error: any) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response: any) => {
    const res = response.data;

    // Blob 类型
    if (res instanceof Blob) {
      return Promise.resolve(res);
    }

    // 授权失败
    if (res.code === 401) {
       const store = useUserStore();
       store.logout();
      return Promise.reject(new Error(res.msg || 'Error'));
    }

    // 错误不弹提示，手动处理
    if (res.code === 412) {
      return Promise.reject(res);
    }

    if (res.code !== 200) {
      showToast(res.msg || 'Error');
      new Error(res.msg || 'Error');
      return Promise.reject(res);
    }
    return res;
  },
  (error: any) => {
    if (error.response) {
      const err = error.response.data;
      showToast(err.msg || 'Error');
      if (err.code === 401) {
         const store = useUserStore();
         store.logout();
      }
    } else if (error.request) {
      if (error.request.readyState == 4 && error.request.status == 0) {
        showToast('请求超时');
      }
    }
    return Promise.reject(error);
  }
);

export default service;
